import React, { Component, useEffect, useState } from "react";
import "./index.scss";
import { graphql, Link } from "gatsby";
import { CategoryType, Theme } from "../../../types/common";

interface IProps {
  categoryType: CategoryType;
  list: Array<string>;
  theme: Theme;
}
const Container = (props: IProps) => {
  return (
    <div className="category-list-container">
      {getTitleByCategory(props.categoryType)}
      <div className="list">
        {props.list &&
          props.list.map(item => {
            return (
              <div className="item-wrapper" key={item}>
                {getLinkByCategory(props.categoryType, item, props.theme)}
              </div>
            );
          })}
      </div>
    </div>
  );
};

function getTitleByCategory(categoryType: CategoryType) {
  if (categoryType === CategoryType.TAGS) {
    return (
      <div className="title">
        <i className="las la-tags"></i> Tags
      </div>
    );
  }
  if (categoryType === CategoryType.SERIES) {
    return (
      <div className="title">
        <i className="las la-copy"></i> 시리즈
      </div>
    );
  }
}

function getLinkByCategory(
  categoryType: CategoryType,
  label: string,
  theme: Theme
) {
  if (categoryType === CategoryType.TAGS) {
    return (
      <Link to={`/${theme.toLowerCase()}/tags/${label}`}>
        <div className="item">{label}</div>
      </Link>
    );
  }
  if (categoryType === CategoryType.SERIES) {
    return (
      <Link to={`/${theme.toLowerCase()}/series/${label}`}>
        <div className="item">{label}</div>
      </Link>
    );
  }
}

export default Container;
