import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Header from "./LayoutHeader";
import { Theme } from "../types/common";

interface IProps {
  theme: Theme;
  children: any;
}

const Container = (props: IProps) => {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: { fields: { prefix: { ne: "" } } }) {
          edges {
            node {
              fields {
                theme
                series
                tags
              }
            }
          }
        }
      }
    `
  );

  let seriesList: Array<any> = [];
  let tagList: Array<any> = [];

  edges
    .filter(v => v.node.fields.theme === props.theme)
    .forEach((edge: any) => {
      seriesList.push(edge.node.fields.series);
      tagList = [...tagList, ...edge.node.fields.tags];
    });
  seriesList = [...new Set(seriesList)].sort();
  tagList = [...new Set(tagList)].sort();

  return <Header {...props} seriesList={seriesList} tagList={tagList} />;
};
export default Container;
