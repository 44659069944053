import React from "react";

import "line-awesome/dist/line-awesome/css/line-awesome.min.css";

import "../../styles/pages/life.scss";

import Header from "../../components/Header";
import SEO from "../../components/seo";
import PostListVerbose from "../../components/PostListVerbose";
import { graphql } from "gatsby";
import { ISEOProps } from "../../types/common";

const seoData: ISEOProps = {
  title: "Life Story of Qus",
  description:
    "쿠스의 일상 이야기 - 지내면서 생기는 여러 이야기들을 기록합니다.",
  // imageUrl: "main/main-space-ship-light.png", TODO: 추가
};

const Container = ({ data }) => {
  const {
    allMarkdownRemark: { edges },
  } = data;

  return (
    <Header theme={"life"}>
      <SEO {...seoData} />
      <div className="content-body-life">
        <div className="intro">
          <p>
            살아가며 생기는
            <br />
            잊고싶지 않은 이야기들을
            <br />
            기록합니다.
          </p>
        </div>

        <div className="sns">
          <a
            href="https://www.facebook.com/qus1225"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="lab la-facebook-square"></i>
          </a>
          <a
            href="https://www.instagram.com/qus1225/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="la la-instagram"></i>
          </a>
        </div>

        <PostListVerbose theme="life" title="최신글" postList={edges} />
      </div>
    </Header>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 3
      sort: { fields: fields___prefix, order: DESC }
      filter: { fields: { theme: { eq: "life" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            thumbNail {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
            series
            prefix(formatString: "YYYY.MM.DD")
          }
          excerpt
        }
      }
    }
  }
`;

export default Container;
