export type Theme = "developer" | "life" | "civicHacker";

export interface ISEOMetaItemType1 {
  name: string;
  content: string;
}

export interface ISEOMetaItemType2 {
  property: string;
  content: string;
}

export interface ISEOProps {
  title?: string;
  description?: string;
  imageUrl?: string;
  keywords?: Array<string>;
  meta?: Array<ISEOMetaItemType1 | ISEOMetaItemType2>;
}

export enum CategoryType {
  TAGS,
  SERIES,
}
